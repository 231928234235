import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

import '../../scss/modules/_errors.scss';

const TRANSLATIONS = 'MODULES.UNHANDLED_ERROR';

export default function Error() {
  const { t } = useTranslation();

  return (
    <ErrorPage headerText={t(`${TRANSLATIONS}.TITLE`)} link={{ to: '/', text: t(`${TRANSLATIONS}.ACTIONS.GO_BACK`) }} />
  );
}
