import { AxiosResponse } from 'axios';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../api';
import useThrowAsyncError from '../hooks/useThrowAsyncError';
import IdentityContext from './IdentityContext';

export type UserInvite = {
  id: string;
  name: string;
  owner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  school: {
    id: string;
    name: string;
    isSmartschool: boolean;
    cities?: string;
  };
  schoolYear: string;
  subjects: string[];
};

type InvitationContextValue = {
  pendingInvitations: UserInvite[];
  handleInvitation: (invitationId: string) => void;
  fetchInvitations: () => Promise<void>;
};

export const defaultInvitationContextValue: InvitationContextValue = {
  pendingInvitations: [],
  handleInvitation: () => {},
  fetchInvitations: () => Promise.resolve(),
};

const InvitationContext = createContext<InvitationContextValue>(defaultInvitationContextValue);

export default InvitationContext;

export const InvitationContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { REACT_APP_P_STUDIO_GROUP } = process.env;

  const [pendingInvitations, setPendingInvitations] = useState<UserInvite[]>([]);

  const { identity } = useContext(IdentityContext);
  const handleError = useThrowAsyncError();
  const handleInvitation = useCallback(
    (invitationId: string) => {
      setPendingInvitations(pendingInvitations.filter(invitation => invitation.id !== invitationId));
    },
    [pendingInvitations],
  );

  const fetchUserInvites = useCallback(async () => {
    try {
      const {
        data: { data: userInvites },
      } = await api.get<AxiosResponse<UserInvite[]>>('/studio/user/invites');

      setPendingInvitations(userInvites);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    if (['SO', 'ES'].includes(REACT_APP_P_STUDIO_GROUP) && identity.isAuthenticated) {
      fetchUserInvites();
    }
  }, [REACT_APP_P_STUDIO_GROUP, identity, fetchUserInvites]);

  const invitationContextValue = useMemo(
    () => ({
      pendingInvitations,
      handleInvitation,
      fetchInvitations: fetchUserInvites,
    }),
    [fetchUserInvites, handleInvitation, pendingInvitations],
  );

  return <InvitationContext.Provider value={invitationContextValue}>{children}</InvitationContext.Provider>;
};
